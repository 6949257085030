<template>
    <div class="file-drop-zone flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
        @drop.prevent="handleDrop" @dragover.prevent @dragenter.prevent>
        <div class="space-y-1 text-center">
            <!-- Your SVG icon here -->
            <!-- ... -->
            <div class="flex text-sm text-gray-600">
                <label for="file-upload"
                    class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none">
                    <span>Vedä/Lataa PDF, DOCX tai TXT tiedostoja tänne</span>
                    <input id="file-upload" name="file-upload" type="file" class="sr-only" accept=".pdf,.docx,.txt"
                        @change="handleFiles($event.target.files)" multiple>
                </label>
            </div>
            <p class="text-xs text-gray-500">Yhteensä max noin 300 sivua - 750 sanaa noin 1 krediitti</p>
            <p class="text-xs text-red-500" v-if="fileWarning">{{ fileWarning }}</p>

        </div>
    </div>
</template>

<script>
import { defineComponent, ref, computed, onUnmounted } from 'vue';
import mammoth from 'mammoth'; // For DOCX files
import { getDocument } from 'pdfjs-dist/legacy/build/pdf'; // For PDF files
import { encode } from 'gpt-tokenizer';
import { useStore } from 'vuex';
export default defineComponent({
    name: 'FileDropZone',
    emits: ['update:files', 'update:fileTexts'],
    setup() {
        const store = useStore();
        const files = ref([]);
        const fileWarning = ref('');

        onUnmounted(() => {
            store.dispatch('files/updateFiles', []);
        });

        const tokenCount = computed(() => {
            const totalTokens = files.value.reduce((total, file) => {
                return total + encode(file.text).length;
            }, 0);
            return Math.round(totalTokens / 1000);
        });

        const validateFiles = (filesToValidate) => {
            const validTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'];
            return filesToValidate.filter(file => validTypes.includes(file.type));
        };

        const addFiles = async (newFiles) => {
            for (const newFile of newFiles) {
                const textContent = await parseFile(newFile);
                let fileInfo = {
                    name: newFile.name,
                    text: textContent
                };
                store.dispatch('files/addFile', fileInfo);
            }
        };


        const removeFile = (index) => {
            store.dispatch('files/removeFile', index);
        };

        const parseFile = async (file) => {
            switch (file.type) {
                case 'application/pdf':
                    return await parsePdf(file);
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    return await parseDocx(file);
                case 'text/plain':
                    return await parseTxt(file);
                default:
                    throw new Error('Unsupported file type');
            }
        };

        const parseDocx = async (file) => {
            const arrayBuffer = await file.arrayBuffer();
            const result = await mammoth.extractRawText({ arrayBuffer });
            return result.value; // The raw text
        };

        const parsePdf = async (file) => {
            const pdf = await getDocument(URL.createObjectURL(file)).promise;
            let textContent = '';
            for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                const page = await pdf.getPage(pageNum);
                const text = await page.getTextContent();
                textContent += text.items.map((item) => item.str).join(' ');
            }
            return textContent;
        };

        const parseTxt = async (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (event) => resolve(event.target.result);
                reader.onerror = (error) => reject(error);
                reader.readAsText(file);
            });
        };

        const handleDrop = async (event) => {
            console.log(event.dataTransfer.files);
            console.log("received");
            fileWarning.value = ''; // Clear any previous warnings
            const droppedFiles = event.dataTransfer.files;
            const validFiles = validateFiles(Array.from(droppedFiles));
            console.log(validFiles);

            if (validFiles.length !== droppedFiles.length) {
                fileWarning.value = 'Vain PDF, DOCX ja TXT tiedostot ovat sallittuja.';
            }

            if (validFiles.length > 0) {
                await addFiles(validFiles);
                event.target.value = ''; // Clear the file input
            }
        };

        const handleFiles = async (fileList) => {
            fileWarning.value = ''; // Clear any previous warnings
            const validFiles = validateFiles(Array.from(fileList));

            if (validFiles.length !== fileList.length) {
                fileWarning.value = 'Vain PDF, DOCX ja TXT tiedostot ovat sallittuja.';
            }

            if (validFiles.length > 0) {
                await addFiles(validFiles);
                fileList.value = ''; // Clear the file input
            }
        };

        return { handleDrop, handleFiles, fileWarning, tokenCount, removeFile, addFiles }
    }
});
</script>

<style scoped>
.file-drop-zone {
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.file-drop-zone:hover {
    background-color: #f3f4f6;
    /* A subtle hint to drop files here */
}
</style>

<template>
    <TheHeader />
    <main class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div v-if="!isLoggedIn" class="max-w-md w-full space-y-8">
            <div>

                <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Kirjaudu sisään Hankeapuri AI:hin
                </h2>
            </div>
            <div class="rounded-lg border bg-white text-card-foreground shadow-sm" data-v0-t="card">
                <div class="flex flex-col space-y-1.5 p-6">
                    <h3 class="font-semibold tracking-tight text-2xl">Kirjaudu sisään</h3>
                    <p class="text-sm text-muted-foreground">Syötä sähköpostiosoitteesi ja salasanasi päästäksesi
                        tiliisi.</p>
                </div>
                <div class="p-6 space-y-4">
                    <div class="space-y-2">
                        <label
                            class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            for="email">
                            Sähköposti
                        </label>
                        <input
                            class="h-10 bg-background text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            id="email" v-model="email" placeholder="m@example.com" required type="email" />
                    </div>
                    <div class="space-y-2">
                        <label
                            class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            for="password">
                            Salasana
                        </label>
                        <input
                            class="h-10 bg-background text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            id="password" v-model="password" required type="password" />
                    </div>
                </div>
                <div class="p-6 flex items-center justify-between">
                    <div class="flex items-center">

                    </div>
                    <div class="text-sm">
                        <a class="font-medium text-indigo-600 hover:text-indigo-500" href="#"
                            @click.prevent="showPasswordResetForm = !showPasswordResetForm">
                            Unohditko salasanasi?
                        </a>
                    </div>
                </div>
            </div>
            <div v-if="showPasswordResetForm" class="mt-4">
                <h3 class="font-semibold tracking-tight text-xl">Palauta salasana</h3>
                <p class="text-sm text-muted-foreground">Syötä sähköpostiosoitteesi, niin lähetämme sinulle salasanan
                    palautuslinkin.</p>
                <div class="mt-2 space-y-2">
                    <label
                        class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        for="reset-email">
                        Sähköposti
                    </label>
                    <input
                        class="h-10 bg-background text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        id="reset-email" v-model="email" placeholder="m@example.com" required type="email" />
                </div>
                <div class="mt-4">
                    <button @click="resetPassword"
                        class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Lähetä palautuslinkki
                    </button>
                </div>
            </div>
            <div>
                <button @click="signInWithEmail"
                    class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Kirjaudu sisään sähköpostilla
                </button>
                <button @click="signInWithGoogle"
                    class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-600 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span class="flex items-center">
                        <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google logo"
                            class="h-5 w-5 mr-2">
                        Kirjaudu sisään Googlella
                    </span>
                </button>
            </div>
            <div v-if="successMessage"
                class="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800"
                role="alert">
                <span class="font-medium">Success!</span> {{ successMessage }}
            </div>
        </div>
    </main>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const router = useRouter();

const email = ref('');
const password = ref('');
const error = ref('');
const successMessage = ref('');
const showPasswordResetForm = ref(false);



const resetPassword = async () => {
    if (!email.value) {
        error.value = "Sinun on syötettävä sähköpostiosoite.";
        return;
    }
    try {
        await store.dispatch('auth/resetPassword', email.value);
        successMessage.value = "Salasanan palautuslinkki on lähetetty sähköpostiisi.";
        showPasswordResetForm.value = false;
    } catch (e) {
        error.value = `Virhe: ${e.message}`;
    }
};

const signInWithEmail = async () => {
    if (!email.value || !password.value) {
        error.value = "Sinun on syötettävä sekä sähköpostiosoite että salasana.";
        return;
    }
    try {
        await store.dispatch('auth/loginWithEmail', { email: email.value, password: password.value });
        successMessage.value = "Kirjautuminen onnistui! Uudelleenohjataan...";
        setTimeout(() => {
            router.push({ name: 'projectList' });
        }, 2000);
    } catch (e) {
        error.value = `Virhe: ${e.message}`;
    }
};

const signInWithGoogle = async () => {
    try {
        await store.dispatch('auth/loginWithGoogle');
        successMessage.value = "Kirjautuminen onnistui! Uudelleenohjataan...";
        setTimeout(() => {
            router.push({ name: 'projectList' });
        }, 2000);
    } catch (e) {
        error.value = `Virhe: ${e.message}`;
    }
};
</script>
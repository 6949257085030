<template>
    <div class="container mx-auto px-4 py-8 bg-gray-50">
        <h2 class="text-3xl font-bold mb-8 text-swatch_5">Hankeapuri Chatbot</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
                <h3 class="text-xl font-semibold mb-2">Yrityksen tiedot</h3>
                <textarea v-model="companyDetails"
                    class="w-full h-48 p-2 border border-gray-300 rounded-md focus:ring-swatch_5 focus:border-swatch_5" />
            </div>
            <div>
                <h3 class="text-xl font-semibold mb-2">Lataa yrityksen tiedot</h3>
                <div class="flex">
                    <input type="text" v-model="yTunnus" placeholder="Syötä Y-tunnus"
                        class="flex-grow focus:ring-swatch_5 focus:border-swatch_5 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md px-4 py-2" />
                    <button @click="loadCompanyInfo" class="ml-4 my-indigo-button">
                        Lataa tiedot
                    </button>
                </div>
            </div>
        </div>
        <div class="mt-8">
            <h3 class="text-xl font-semibold mb-2">Chatbot</h3>
            <div v-if="error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                <strong class="font-bold">Error:</strong>
                <span class="block sm:inline">{{ error }}</span>
            </div>
            <div class="border border-gray-300 rounded-md p-4 mb-4 bg-gray-100">
                <div ref="chatArea" class="h-64 overflow-y-auto">
                    <div v-for="(message, index) in chatHistory" :key="index" class="mb-4"
                        :class="{ 'text-right': message.sender === 'Käyttäjä' }">
                        <div class="font-semibold"
                            :class="{ 'text-swatch_5': message.sender === 'Käyttäjä', 'text-swatch_6': message.sender === 'Chatbot' }">
                            {{ message.sender }}
                        </div>
                        <div class="p-2 rounded-md inline-block"
                            :class="{ 'bg-swatch_2': message.sender === 'Käyttäjä', 'bg-swatch_3': message.sender === 'Chatbot' }">
                            {{ message.text }}
                        </div>
                    </div>
                </div>
                <div v-if="loading" class="flex items-center mt-4">
                    <div class="w-4 h-4 mr-2 rounded-full bg-swatch_5 animate-pulse"></div>
                    <span class="text-gray-500">Chatbot is typing...</span>
                </div>
                <div class="flex mt-4">
                    <input type="text" v-model="message" placeholder="Kirjoita viesti"
                        class="flex-grow focus:ring-swatch_5 focus:border-swatch_5 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md px-4 py-2"
                        @keyup.enter="sendMessage" />
                    <button @click="sendMessage" class="ml-4 my-indigo-button">
                        Lähetä
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import axios from 'axios';

export default {
    setup() {
        const companyDetails = ref('');
        const yTunnus = ref('');
        const message = ref('');
        const chatHistory = ref([]);
        const loading = ref(false);
        const error = ref(null);
        const chatArea = ref(null);

        const loadCompanyInfo = async () => {
            try {
                const response = await axios.get(`/api/company-info?yTunnus=${yTunnus.value}`);
                companyDetails.value = response.data.companyDetails;
                error.value = null;
            } catch (err) {
                console.error('Error loading company info:', err);
                error.value = 'Failed to load company information. Please try again.';
            }
        };

        const sendMessage = async () => {
            try {
                loading.value = true;
                chatHistory.value.push({ sender: 'Käyttäjä', text: message.value });
                const response = await axios.post('https://www.jhappssandbox.com/gptserver/chat/hankeapuriapigrants.php', {
                    userDetails: companyDetails.value,
                    chatHistory: chatHistory.value,
                });
                const chatbotResponse = response.data.response;
                chatHistory.value.push({ sender: 'Chatbot', text: chatbotResponse });
                message.value = '';
                loading.value = false;
                error.value = null;
            } catch (err) {
                console.error('Error sending message:', err);
                loading.value = false;
                error.value = 'Failed to send message. Please try again.';
            }
        };

        watch(chatHistory, () => {
            chatArea.value.scrollTop = chatArea.value.scrollHeight;
        });

        onMounted(() => {
            chatArea.value.scrollTop = chatArea.value.scrollHeight;
        });

        return {
            companyDetails,
            yTunnus,
            message,
            chatHistory,
            loading,
            error,
            chatArea,
            loadCompanyInfo,
            sendMessage,
        };
    },
};
</script>

<style>
body {
    font-family: 'Poppins', sans-serif;
}

.my-indigo-button {
    @apply inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-swatch_5 hover:bg-swatch_4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-swatch_5;
}
</style>
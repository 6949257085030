// src/store/modules/auth.js
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

import router from "@/router";
import { getDatabase, ref, set, get } from "firebase/database";

const state = {
  user: null,
  isAuthenticated: false,
};

const mutations = {
  setUser(state, user) {
    state.user = user;
    state.isAuthenticated = !!user; // Update isAuthenticated based on user
  },
};

const actions = {
  setUser({ commit }, user) {
    commit("setUser", user);
  },
  async logout({ commit }) {
    const auth = getAuth();
    try {
      await signOut(auth);

      commit("setUser", null);
      console.log("User signed out successfully");
      router.push({ name: "LandingPage" });
    } catch (error) {
      console.error("Error signing out:", error);
    }
  },
  async loginWithEmail({ commit }, { email, password }) {
    const auth = getAuth();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      commit("setUser", userCredential.user);
      router.push({ name: "projectList" });
    } catch (error) {
      console.error("Error logging in with email:", error);
      throw error;
    }
  },
  async resetPassword(_, email) {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      console.log("Password reset email sent successfully");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      throw error;
    }
  },
  async loginWithGoogle({ commit }) {
    const auth = getAuth();
    const db = getDatabase();

    try {
      const userCredential = await signInWithPopup(
        auth,
        new GoogleAuthProvider()
      );
      const user = userCredential.user;

      // Check if the user exists in the Firebase Realtime Database
      const userRef = ref(db, `users/${user.uid}`);
      const snapshot = await get(userRef);

      if (!snapshot.exists()) {
        // If the user doesn't exist, add initial credits to their account
        await set(userRef, {
          credits: 500,
        });
      }

      commit("setUser", user);
      router.push({ name: "projectList" });
    } catch (error) {
      console.error("Error logging in with Google:", error);
      throw error;
    }
  },
  async registerWithEmail({ commit }, { email, password }) {
    const auth = getAuth();
    const db = getDatabase();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Add initial credits to the user in the Firebase Realtime Database
      await set(ref(db, `users/${user.uid}`), {
        credits: 500,
      });

      commit("setUser", user);
    } catch (error) {
      console.error("Error registering user:", error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

// src/store/modules/files.js

import { encode } from "gpt-tokenizer";
const state = {
  files: [],
};

const mutations = {
  setFiles(state, files) {
    state.files = files;
  },
  addFile(state, file) {
    const tokenCount = encode(file.text).length;
    console.log(state.files);
    state.files.push({
      ...file,
      tokenCount,
      creditCount: Math.round(tokenCount / 1000),
    });
  },
  removeFile(state, index) {
    console.log(state.files);
    state.files.splice(index, 1);
    console.log(state.files);
  },
};

const actions = {
  updateFiles({ commit }, files) {
    commit("setFiles", files);
  },
  addFile({ commit }, file) {
    commit("addFile", file);
  },
  removeFile({ commit, state }, index) {
    const updatedFiles = [...state.files];
    updatedFiles.splice(index, 1);
    commit("setFiles", updatedFiles);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

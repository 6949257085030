<template>
    <header class="px-4 lg:px-6 h-14 flex items-center">
        <a class="flex items-center justify-center" href="/" rel="ugc">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-6 w-6">
                <path
                    d="M9.5 2A2.5 2.5 0 0 1 12 4.5v15a2.5 2.5 0 0 1-4.96.44 2.5 2.5 0 0 1-2.96-3.08 3 3 0 0 1-.34-5.58 2.5 2.5 0 0 1 1.32-4.24 2.5 2.5 0 0 1 1.98-3A2.5 2.5 0 0 1 9.5 2Z">
                </path>
                <path
                    d="M14.5 2A2.5 2.5 0 0 0 12 4.5v15a2.5 2.5 0 0 0 4.96.44 2.5 2.5 0 0 0 2.96-3.08 3 3 0 0 0 .34-5.58 2.5 2.5 0 0 0-1.32-4.24 2.5 2.5 0 0 0-1.98-3A2.5 2.5 0 0 0 14.5 2Z">
                </path>
            </svg>
            <span class="sr-only">Hankeapuri AI</span>
        </a>
        <nav class="ml-auto flex gap-4 sm:gap-6">
            <a v-if="!isLoggedIn" class="text-sm font-medium hover:underline underline-offset-4" href="/login"
                rel="ugc">
                Kirjaudu sisään
            </a>
            <a v-else class="text-sm font-medium hover:underline underline-offset-4" href="/hankeapuri" rel="ugc">
                Aloita
            </a>
        </nav>
    </header>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
    name: 'TheHeader',
    setup() {
        const store = useStore();

        const isLoggedIn = computed(() => store.state.auth.isAuthenticated);

        return {
            isLoggedIn,
        };
    },
};
</script>

<style scoped>
/* Your header-specific styles here */
</style>
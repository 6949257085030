// src/composables/useAuthenticationCheck.js
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default function useAuthenticationCheck() {
  const store = useStore();
  const router = useRouter();

  const isAuthenticated = computed(() => store.state.auth.isAuthenticated);

  const checkAuthentication = () => {
    if (!isAuthenticated.value) {
      // Redirect to the login page if the user is not authenticated
      router.push('/login');
    }
  };

  return {
    checkAuthentication,
  };
}
<template>
    <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"></span>
            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full"
                role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <h2 class="text-2xl font-semibold mb-6">Valmiit mallipohjat</h2>
                    <div class="max-h-[70vh] overflow-y-auto">
                        <div class="grid grid-cols-1 gap-6">
                            <div v-for="(template, index) in sortedTemplates" :key="index"
                                @click="loadTemplate(template)"
                                class="bg-white rounded-lg shadow-md p-6 cursor-pointer transition-all duration-200 hover:shadow-lg hover:transform hover:scale-105 flex items-center justify-between">
                                <div class="flex-1">
                                    <div class="text-xl font-semibold">{{ template.name }}</div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500 ml-4" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button type="button"
                        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        @click="closeModal">
                        Sulje
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>



<script setup>
import { ref, onMounted, computed } from 'vue';
import { defineEmits } from 'vue';
import { database } from "@/firebase.js";
import { ref as firebaseRef, get } from "firebase/database";

const emit = defineEmits(['templateSelected', 'closeModal']);
const templates = ref([]);

const fetchHankes = async (userId) => {
    const hankeRef = firebaseRef(database, 'hankkeet');
    try {
        const snapshot = await get(hankeRef);
        if (snapshot.exists()) {
            const allHankes = Object.entries(snapshot.val())
                .map(([id, hanke]) => ({ id, ...hanke }))
                .filter(hanke => hanke.userId === userId);
            templates.value = allHankes.map(hanke => ({
                id: hanke.id,
                name: hanke.name,
                details: hanke.details,
                sections: Object.entries(hanke.sections || {}).map(([id, section]) => ({
                    id,
                    ...section,
                    subSections: Object.entries(section.subSections || {}).map(([subId, subSection]) => ({
                        id: subId,
                        ...subSection,
                    })),
                })),
            }));
        } else {
            templates.value = []; // No more Hankes, all alone in the digital void
        }
    } catch (error) {
        console.error("Error fetching Hankes:", error);
    }
};

onMounted(async () => {
    const adminUserId = '9jpCD3noenQoBwWraiIAundyxJl2';
    await fetchHankes(adminUserId);
});

const sortedTemplates = computed(() => {
    return [...templates.value].sort((a, b) => a.name.localeCompare(b.name));
});

const loadTemplate = (template) => {
    const sortedTemplate = {
        ...template,
        sections: template.sections.sort((a, b) => a.order - b.order),
    };
    emit('templateSelected', sortedTemplate);
};

const closeModal = () => {
    emit('closeModal');
};
</script>
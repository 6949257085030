<template>
    <div class="container mx-auto px-4 py-8">
        <h1 class="text-3xl font-bold mb-4">Yksityisyyskäytäntö</h1>
        <p class="text-gray-600 mb-8">Päivitetty viimeksi: 4.4.2024</p>


        <div class="mb-8">
            <h2 class="text-2xl font-bold mb-2">1. Kerättävät tiedot</h2>
            <ul class="list-disc pl-6">
                <li>Rekisteröityessä keräämme nimen, sähköpostiosoitteen ja yrityksen tiedot</li>
                <li>Käytön yhteydessä keräämme tietoja hankerahoitushakemuksiin syötetyistä tiedoista</li>
                <li>Keräämme myös analytiikkatietoja sovelluksen käytöstä sivuston kehittämiseksi</li>
            </ul>
        </div>

        <div class="mb-8">
            <h2 class="text-2xl font-bold mb-2">2. Tietojen käyttö</h2>
            <ul class="list-disc pl-6">
                <li>Henkilötietoja käytetään käyttäjätilin luomiseen ja hallintaan</li>
                <li>Hankerahoitushakemusten tietoja käytetään hakemuspohjien generointiin tekoälyllä</li>
                <li>Voimme lähettää tuotepäivityksiä ja uutiskirjeitä sähköpostilla</li>
            </ul>
        </div>

        <div class="mb-8">
            <h2 class="text-2xl font-bold mb-2">3. Tietojen jakaminen</h2>
            <ul class="list-disc pl-6">
                <li>Tietoja ei myydä tai jaeta kolmansille osapuolille</li>
                <li>Käytämme luotettuja alihankkijoita, kuten Google Firebasea, tietojen tallentamiseen ja käsittelyyn
                </li>
                <li>Käytämme OpenAI API -rajapintaa hanketekstien generointiin. Tietojasi ei käytetä uusien
                    tekoälymallien kouluttamiseen</li>
                <li>Tietoja voidaan luovuttaa lain vaatiessa viranomaisille</li>
            </ul>
        </div>

        <div class="mb-8">
            <h2 class="text-2xl font-bold mb-2">4. Tietoturva</h2>
            <ul class="list-disc pl-6">
                <li>Noudatamme alan standardeja tietojen suojaamisessa</li>
                <li>Käytämme SSL-salausta tietojen siirrossa</li>
                <li>Rajaamme pääsyn tietoihin vain niitä tarvitseville</li>
                <li>Havaituista tietoturvaloukkauksista ilmoitetaan käyttäjille</li>
            </ul>
        </div>

        <div class="mb-8">
            <h2 class="text-2xl font-bold mb-2">5. Pääsy ja muutokset tietoihin</h2>
            <ul class="list-disc pl-6">
                <li>Käyttäjä voi tarkastella ja päivittää tietojaan kirjautumalla tilille</li>
                <li>Käyttäjä voi pyytää tietojensa poistoa ottamalla yhteyttä</li>
            </ul>
        </div>

        <div class="mb-8">
            <h2 class="text-2xl font-bold mb-2">6. Maksaminen</h2>
            <ul class="list-disc pl-6">
                <li>Käytämme Lemon Squeezy -maksupalvelua turvalliseen maksamiseen</li>
                <li>Emme tallenna tai käsittele luottokorttitietoja itse</li>
            </ul>
        </div>

        <div class="mb-8">
            <h2 class="text-2xl font-bold mb-2">7. Evästeet</h2>
            <ul class="list-disc pl-6">
                <li>Käytämme evästeitä käyttökokemuksen parantamiseen ja analytiikkaan</li>
                <li>Käyttäjä voi hallita evästeasetuksia selaimensa kautta</li>
            </ul>
        </div>

        <div class="mb-8">
            <h2 class="text-2xl font-bold mb-2">8. Muutokset käytäntöön</h2>
            <ul class="list-disc pl-6">
                <li>Voimme päivittää Yksityisyyskäytäntöä ajoittain</li>
                <li>Merkittävistä muutoksista ilmoitetaan käyttäjille sähköpostitse</li>
                <li>Käytäntö astuu voimaan heti julkaisusta</li>
            </ul>
        </div>

        <div>
            <h2 class="text-2xl font-bold mb-2">9. Yhteystiedot</h2>
            <p class="text-gray-600">Yksityisyyteen liittyvissä kysymyksissä voi ottaa yhteyttä osoitteeseen <a
                    href="mailto:privacy@hankeapuri.ai" class="text-blue-600 hover:underline">hello@jh-apps.com</a>
            </p>
        </div>
    </div>
</template>


<script>
export default {
    name: 'PrivacyView',
}
</script>
// src/store/modules/content.js
const state = {
  editableContent: "",
  aiResponse: null,
  isProcessing: {
    submitEdit: false,
  },
  loadingStates: {},
};

const mutations = {
  setEditableContent(state, content) {
    state.editableContent = content;
  },
  setAiResponse(state, response) {
    state.aiResponse = response;
  },
  setIsProcessing(state, { key, value }) {
    state.isProcessing[key] = value;
  },
  setLoadingState(state, { key, value }) {
    state.loadingStates[key] = value;
  },
};

const actions = {
  updateEditableContent({ commit }, content) {
    commit("setEditableContent", content);
  },
  updateAiResponse({ commit }, payload) {
    commit("setAiResponse", payload);
  },
  updateIsProcessing({ commit }, { key, value }) {
    commit("setIsProcessing", { key, value });
  },
  updateLoadingState({ commit }, { key, value }) {
    commit("setLoadingState", { key, value });
  },
};

const getters = {
  getEditableContent(state) {
    return state.editableContent;
  },
  getAiResponse(state) {
    return state.aiResponse;
  },
  handleSubmitEdit(state) {
    return state.isProcessing;
  },
  getIsProcessing(state) {
    return state.isProcessing;
  },
  getLoadingStates(state) {
    return state.loadingStates;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

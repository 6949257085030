<template>
    <div class="min-h-screen bg-gray-100 text-gray-900">
        <header class="bg-white shadow px-4 lg:px-6 h-14 flex items-center">
            <div class="max-w-7xl px-4 sm:px-6 lg:px-8">
                <h1 class="text-3xl font-bold leading-tight">Genii AI</h1>
            </div>
            <nav class="ml-auto flex gap-4 sm:gap-6">
                <a class="text-sm font-medium hover:underline underline-offset-4" href="#" rel="ugc">Log Out</a>

            </nav>
        </header>
        <main>
            <div class="py-12">
                <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div class="bg-white overflow-hidden shadow-xl sm:rounded-lg p-6">
                        <div class="mb-4">
                            <label for="hankeSelect" class="block text-sm font-medium text-gray-700">Pick a Report</label>
                            <select id="hankeSelect" v-model="selectedHanke"
                                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <option disabled value="">Choose One</option>
                                <option v-for="hanke in hankes" :key="hanke.id" :value="hanke.id">{{ hanke.Hankename }}
                                </option>
                            </select>
                        </div>
                        <div class="mt-6">
                            <FileDropZone v-model:files="files" />
                            <ul class="file-list list-none p-0 m-0">
                                <li v-for="(file, index) in files" :key="index"
                                    class="file-item flex justify-between items-center p-4 border-b border-gray-300 hover:bg-gray-100 transition-colors duration-300">
                                    {{ file.name }}
                                    <button @click="removeFile(index)"
                                        class="ml-4 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition-colors duration-300">Remove</button>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-6">
                            <label for="inputText" class="block text-sm font-medium text-gray-700">Write / transcribe your
                                information here</label>
                            <textarea id="inputText" v-model="inputText" rows="3"
                                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"></textarea>
                            <button @click="toggleRecording"
                                class="record-button bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-indigo-300 mt-4 py-2 px-4 ..."
                                aria-label="Start or stop recording">

                                <font-awesome-icon v-show="!isRecording" :icon="['fas', 'microphone']" class="text-white" />
                                <font-awesome-icon v-show="isRecording" :icon="['fas', 'microphone-slash']"
                                    class="text-white" />

                                <div v-show="loading"
                                    class="spinner-border animate-spin inline-block w-3 h-3 border-4 rounded-full"
                                    role="status">

                                </div>
                            </button>
                        </div>
                        <div class="mt-6">
                            <button @click="handleLaunchAI"
                                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none mr-4">
                                <span v-show="!loadingAI">Launch AI</span>
                                <span v-show="loadingAI">Wait a moment and drink another cup of coffee</span>
                            </button>


                            <button @click="generatePDF"
                                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none">Save
                                as
                                PDF</button>
                        </div>
                    </div>
                </div>
            </div>
            <AiResponseCard :aiResponse="aiResponse" @ai-fixes="aiFixes" />
            <div v-if="showFeedbackModal" class="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center">
                <div class="bg-white p-6 rounded-lg shadow-xl max-w-lg w-full mx-4">
                    <h3 class="text-lg font-semibold text-gray-900 mb-4">Miten muokataan?</h3>
                    <textarea v-model="feedbackText" placeholder="Kirjoita muokkausehdotuksesi"
                        class="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"></textarea>
                    <div v-if="loadingEdits" class="flex justify-center items-center mt-4">
                        <div class="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-500"></div>
                    </div>
                    <div class="flex justify-end space-x-4 mt-4">
                        <button @click="handleSubmitFeedback"
                            class="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50">Lähetä</button>
                        <button @click="closeFeedbackModal"
                            class="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50">Peruuta</button>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>


import { database } from "@/firebase.js";
import { ref as firebaseRef, get } from "firebase/database";


import { transcribeAudio, launchAI, submitFeedback } from '@/services/apiService';
import { ref, onMounted } from 'vue';
import { jsPDF } from 'jspdf';
import axios from 'axios';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf';
import AiResponseCard from './AiResponseCard.vue';
GlobalWorkerOptions.workerSrc = '/pdf.worker.mjs';
import FileDropZone from './FileDropZone.vue';
export default {
    name: 'HankeApuri',
    components: {
        AiResponseCard,
        FileDropZone
    },
    setup() {
        const files = ref([]);
        const selectedHanke = ref('');
        const hankes = ref([]);
        const inputText = ref('');
        const aiResponse = ref(null);
        const showFeedbackModal = ref(false);
        const feedbackText = ref('');
        const feedbackTarget = ref(null);
        const loadingAI = ref(false);
        const loadingEdits = ref(false);
        const isRecording = ref(false);
        let mediaRecorder = ref(null);
        const audioChunks = ref([]);



        async function sendAudioToCloud(base64Audio) {
            try {
                const response = await transcribeAudio(base64Audio);
                // Handle the response as before
                if (response && response.data.text) {
                    inputText.value += response.data.text; // Update the inputText with the transcribed text
                }
            } catch (error) {
                // Handle the error as before
            }
        }

        const handleLaunchAI = async () => {
            loadingAI.value = true;
            const formData = new FormData();

            const selectedHankeDetails = hankes.value.find(hanke => hanke.id === selectedHanke.value);
            const sortedHankeJSON = selectedHankeDetails.HankeJSON.sort((a, b) => a.order - b.order);
            const hankeJSONAsObject = sortedHankeJSON.reduce((obj, item) => {
                obj[item.key] = item.value;
                return obj;
            }, {});

            // Make sure we actually have a selected Hanke
            if (!selectedHankeDetails) {
                console.error("No Hanke selected, or it's missing. How can you launch AI without a target? Select a Hanke and try again, please.");
                loadingAI.value = false;
                return;
            }

            formData.append('Hankename', selectedHankeDetails.Hankename);
            formData.append('HankeJSON', JSON.stringify(hankeJSONAsObject));
            formData.append('Hankedetails', selectedHankeDetails.Hankedetails);

            // Include the input text in the formData
            formData.append('text', inputText.value);

            // Process each file and extract text
            for (const file of files.value) {
                try {
                    const textContent = await parsePdf(file);
                    formData.append(file.name + '_text', textContent); // Append the extracted text with a key indicating the file
                } catch (error) {
                    console.error('Error extracting text from PDF:', error);
                }
            }

            // Now send the formData to the server
            try {
                const response = await launchAI(formData);
                console.log(response.data);
                aiResponse.value = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
            } catch (error) {
                console.error('Error posting to PHP backend:', error);
            } finally {
                loadingAI.value = false;
            }
        };

        // Refactor the method that submits feedback
        const handleSubmitFeedback = async () => {
            loadingEdits.value = true;
            const formData = new FormData();

            // Include the feedback text
            formData.append('feedbackText', feedbackText.value);

            // Include the entire aiResponse as context
            formData.append('context', JSON.stringify(aiResponse.value));

            // Include the target of the feedback
            const targetTextContent = aiResponse.value[feedbackTarget.value];
            formData.append('target_text', JSON.stringify(targetTextContent));

            // Include any files that are part of the feedback
            files.value.forEach((file, index) => {
                formData.append(`file${index}`, file);
            });

            try {
                const response = await submitFeedback(formData);
                // Handle the response in the same way as we did before
                const responseJson = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
                console.log('Feedback submitted:', responseJson);
                // Assuming we have a method to handle the feedback submission
                handleFeedbackSubmission(responseJson, feedbackTarget.value);
            } catch (error) {
                console.error('Error submitting feedback:', error);
            } finally {
                loadingEdits.value = false;
                feedbackText.value = ''; // Reset the feedback text
                // Close the feedback modal or reset any other state as necessary
                closeFeedbackModal();

            }
        };

        // This function is the maestro of the recording orchestra
        const toggleRecording = async () => {
            if (!isRecording.value) {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

                mediaRecorder.value = new MediaRecorder(stream, { mimeType: 'audio/webm' });
                audioChunks.value = [];

                mediaRecorder.value.ondataavailable = (event) => {
                    audioChunks.value.push(event.data);
                };

                mediaRecorder.value.onstop = async () => {
                    const audioBlob = new Blob(audioChunks.value, { type: 'audio/webm' });
                    const reader = new FileReader();

                    reader.onloadend = () => {

                        const base64AudioMessage = reader.result.toString().split(',')[1];

                        sendAudioToCloud(base64AudioMessage);
                    };

                    reader.readAsDataURL(audioBlob);
                };

                mediaRecorder.value.start();
                isRecording.value = true;
            } else {
                mediaRecorder.value.stop();
                isRecording.value = false;
            }
        };

        const generatePDF = () => {
            const doc = new jsPDF();
            const aiResponseData = aiResponse.value || {};
            let yPos = 10;

            Object.values(aiResponseData).forEach((value) => {
                let text = '';
                if (typeof value === 'object') {
                    text = JSON.stringify(value, null, 2)
                        .replace(/[{}[\]"]/g, '')
                        .replace(/,/g, '');
                } else {
                    text = value.toString();
                }

                const lines = doc.splitTextToSize(text, 190);

                lines.forEach((line) => {
                    doc.text(line, 10, yPos);
                    yPos += 10;

                    if (yPos > 280) {
                        doc.addPage();
                        yPos = 10;
                    }
                });
            });

            doc.save('ai-response-polished.pdf');
        };

        const aiFixes = (key) => {
            feedbackTarget.value = key;
            showFeedbackModal.value = true;
        };

        const closeFeedbackModal = () => {
            showFeedbackModal.value = false;
            feedbackText.value = '';
        };

        const handleFeedbackSubmission = (feedbackData, target) => {

            try {
                if (aiResponse.value[target]) {
                    for (const [key, value] of Object.entries(feedbackData)) {
                        aiResponse.value[target][key] = value; // <-- Apply changes to the correct target
                    }
                }
            } catch (error) {
                console.error('Error updating feedback:', error);
            }
        };

        const getChangedTexts = () => {
            return {};
        };

        const removeFile = (index) => {
            files.value.splice(index, 1);
        };

        const parsePdf = async (file) => {
            try {
                const pdf = await getDocument(URL.createObjectURL(file)).promise;
                const textContent = await extractTextFromPdf(pdf);
                console.log(textContent);
                return textContent;
            } catch (error) {
                console.error('Error parsing PDF:', error);
            }
        };

        const extractTextFromPdf = async (pdf) => {
            let textContent = '';

            for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                const page = await pdf.getPage(pageNum);
                const text = await page.getTextContent();
                textContent += text.items.map((item) => item.str).join(' ');
            }

            return textContent;
        };

        onMounted(async () => {
            window.addEventListener('beforeunload', () => {
                if (isRecording.value && mediaRecorder) {
                    mediaRecorder.value.stop();
                }
            });

            const hankeRef = firebaseRef(database, 'hankecollection');
            try {
                const snapshot = await get(hankeRef);
                if (snapshot.exists()) {
                    hankes.value = Object.entries(snapshot.val()).map(([id, hanke]) => ({
                        id,
                        Hankename: hanke.Hankename,
                        HankeJSON: hanke.HankeJSON.sort((a, b) => a.order - b.order),
                        Hankedetails: hanke.Hankedetails,
                    }));
                }
            } catch (error) {
                console.error("Error fetching Hankes:", error);
            }
        });


        const demoAI = async () => {
            loadingAI.value = true;

            try {
                const formData = new FormData();

                files.value.forEach((file, index) => {
                    formData.append(`file${index}`, file);
                });

                formData.append('text', inputText.value);

                const response = await axios.post('https://www.jhappssandbox.com/gptserver/chat/hankeapuri.php', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                console.log(response.data);
                aiResponse.value = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
            } catch (error) {
                console.error('Error posting to PHP backend:', error);
            } finally {
                loadingAI.value = false;
            }
        };

        const randomAI = async () => {
            loadingAI.value = true;

            try {
                const formData = new FormData();

                files.value.forEach((file, index) => {
                    formData.append(`file${index}`, file);
                });

                formData.append('text', inputText.value);

                const response = await axios.post('https://www.jhappssandbox.com/gptserver/chat/hankeapuriAPI.php', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                console.log(response.data);
                aiResponse.value = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
            } catch (error) {
                console.error('Error posting to PHP backend:', error);
            } finally {
                loadingAI.value = false;
            }
        };

        return {
            files,
            selectedHanke,
            inputText,
            aiResponse,
            showFeedbackModal,
            feedbackText,
            feedbackTarget,
            loadingAI,
            loadingEdits,
            isRecording,
            mediaRecorder,
            audioChunks,

            toggleRecording,
            generatePDF,
            aiFixes,
            closeFeedbackModal,

            getChangedTexts,

            removeFile,
            parsePdf,
            extractTextFromPdf,

            demoAI,
            randomAI,
            handleLaunchAI,
            handleSubmitFeedback,
            sendAudioToCloud,
            hankes

        };
    },


};


</script>

<style scoped>
.bg-white.overflow-hidden.shadow-xl.sm\:rounded-lg.p-6 {
    padding: 2rem;
    /* More padding, more power */
}

@keyframes pulse {

    0%,
    100% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.1);
        opacity: 0.7;
    }
}




.record-button {
    /* Styling for the button itself */
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
    /* For absolute positioning of the icon */
}

/* The non-recording state style */
.record-button:not(.is-recording) {
    background-color: #4caf50;
    /* A calming green */
}

/* The recording state style */
.record-button.is-recording {
    background-color: #ff4136;
    /* That fiery red again */
}

.record-button:hover {
    /* Let's make the hover state a bit darker for both scenarios */
    opacity: 0.9;
}

.record-icon {
    /* The base style for our icon */
    height: 20px;
    width: 20px;
    border-radius: 50%;
    /* Circle it up */
    transition: transform 0.3s;
}

/* The non-recording icon style */
.record-icon:not(.recording) {
    background-color: #fff;
    /* A nice, visible white */
}

/* The recording icon style */
.record-icon.recording {
    background-color: #fff;
    /* Keep it white to stand out */
    animation: pulse 1.5s infinite;
    /* Here come the jazz hands! */
}

/* The pulse animation for when we're recording */
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.1);
        opacity: 0.7;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}
</style>
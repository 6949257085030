// apiService.js
import axios from "axios";

import { saveAs } from "file-saver";

const API_BASE_URL = "https://www.jhappssandbox.com/gptserver/chat";
const {
  Document,
  Packer,
  Paragraph,
  TextRun,
  HeadingLevel,
  AlignmentType,
} = require("docx");

// Function to transcribe audio
export async function transcribeAudio(base64Audio) {
  try {
    const response = await axios({
      method: "post",
      url: "https://us-central1-businessdemos.cloudfunctions.net/transcribeAudio",
      data: {
        audioContent: base64Audio,
      },
      withCredentials: false,
    });
    // Assuming the response has a structure { text: "Transcribed text" }
    return response;
  } catch (error) {
    console.error("Alas, the digital gods are displeased:", error);
    throw error; // Let's throw the error so the caller knows something went awry.
  }
}

// Function to launch AI
export function launchAI(formData) {
  return axios
    .post(`${API_BASE_URL}/hankeapuriAPI.php`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      const tokenUsage = response.data.tokenUsage; // Assuming the token usage is returned in the response
      return {
        data: response.data,
        tokenUsage: tokenUsage,
      };
    });
}

// Function to submit feedback
export function submitFeedback(formData) {
  return axios
    .post(`${API_BASE_URL}/hankeapuriAPIMuokkaus.php`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      const tokenUsage = response.data.tokenUsage; // Assuming the token usage is returned in the response
      if (response.data.text) {
        return {
          data: response.data.text,
          tokenUsage: tokenUsage,
        };
      } else {
        return {
          data: response.data,
          tokenUsage: tokenUsage,
        };
      }
    });
}

function createParagraphsFromAiResponse(aiResponse, textSize = 24) {
  let paragraphs = [];

  // Iterate over each section in the AI response
  for (const [sectionKey, sectionValue] of Object.entries(aiResponse)) {
    // Add a heading for the section
    paragraphs.push(
      new Paragraph({
        text: sectionKey,
        heading: HeadingLevel.HEADING_1,
        size: textSize + 10,
      })
    );

    // Check if the sectionValue is an object itself and iterate over its keys
    if (typeof sectionValue === "object" && !Array.isArray(sectionValue)) {
      for (const [subKey, subValue] of Object.entries(sectionValue)) {
        // Add a paragraph for each sub-item
        paragraphs.push(
          new Paragraph({
            children: [
              new TextRun({
                text: subKey + ": ",
                size: textSize + 4,
                bold: true,
              }),
              new TextRun({ text: subValue, size: textSize }),
            ],
          })
        );
      }
    } else {
      // If it's not an object, just add a single paragraph
      paragraphs.push(new Paragraph({ text: sectionValue, size: textSize }));
    }
  }

  return paragraphs;
}

export function generateDocx(aiResponse, textSize = 24) {
  // Create a new Document instance
  const doc = new Document({
    title: "AI-Generated Document",
    sections: [
      {
        properties: {
          page: {
            margin: {
              top: 1000,
              right: 1000,
              bottom: 1000,
              left: 1000,
            },
          },
        },
        children: [
          new Paragraph({
            text: "AI-Generated Document",
            heading: HeadingLevel.TITLE,
            alignment: AlignmentType.CENTER,
            size: textSize,
          }),
          ...createParagraphsFromAiResponse(aiResponse, textSize),
        ],
      },
    ],
  });

  // Use Packer to create a Blob from the Document
  Packer.toBlob(doc)
    .then((blob) => {
      // Use FileSaver to save the Blob as a DOCX file
      saveAs(blob, "ai-response.docx");
    })
    .catch((error) => {
      console.error("Error generating docx:", error);
    });
}

<template>
  <div class="min-h-screen bg-gray-100 text-gray-900">
    <!-- Header Section -->
    <header class="px-4 lg:px-6 py-4 bg-white shadow">
      <div class="max-w-7xl mx-auto flex justify-between items-center">
        <!-- Brain Icon -->
        <a class="flex items-center" href="/" rel="ugc">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="text-indigo-600">
            <path
              d="M9.5 2A2.5 2.5 0 0 1 12 4.5v15a2.5 2.5 0 0 1-4.96.44 2.5 2.5 0 0 1-2.96-3.08 3 3 0 0 1-.34-5.58 2.5 2.5 0 0 1 1.32-4.24 2.5 2.5 0 0 1 1.98-3A2.5 2.5 0 0 1 9.5 2Z">
            </path>
            <path
              d="M14.5 2A2.5 2.5 0 0 0 12 4.5v15a2.5 2.5 0 0 0 4.96.44 2.5 2.5 0 0 0 2.96-3.08 3 3 0 0 0 .34-5.58 2.5 2.5 0 0 0-1.32-4.24 2.5 2.5 0 0 0-1.98-3A2.5 2.5 0 0 0 14.5 2Z">
            </path>
          </svg>
          <span class="ml-2 text-2xl font-bold text-gray-800">Hankeapuri AI</span>
        </a>
        <!-- Logout Button -->
        <button @click="logout"
          class="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Kirjaudu ulos
        </button>
      </div>
    </header>


    <!-- Main Content -->
    <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div class="px-4 py-6 sm:px-0">
        <div class="bg-white shadow-md rounded-lg p-6">
          <div class="mb-6">
            <h2 class="text-2xl font-bold text-gray-800 mb-4">Omat hankkeet</h2>
            <div class="flex space-x-4">
              <router-link to="/hankeapuri"
                class="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Luo uusi hanke
              </router-link>
              <router-link to="/hankepohjat"
                class="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Luo uusi hankepohja
              </router-link>
            </div>
          </div>
          <ul v-if="projects.length" class="space-y-4">
            <li v-for="project in projects" :key="project.id"
              class="flex justify-between items-center bg-gray-100 rounded-md p-4">
              <a @click="loadProject(project.id)"
                class="text-lg font-medium text-indigo-600 hover:text-indigo-800 cursor-pointer">
                {{ project.name }}
              </a>
              <button @click="openDeleteModal(project)" class="text-red-600 hover:text-red-800 focus:outline-none">
                <font-awesome-icon icon="trash" class="h-5 w-5" />
              </button>
            </li>
          </ul>
          <div v-else class="text-center">
            <p class="text-xl text-gray-600">Ei tallennettuja hankkeita</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Project Modal -->
    <div v-if="showDeleteModal" class="fixed inset-0 flex items-center justify-center z-50">
      <div class="absolute inset-0 bg-gray-900 opacity-50"></div>
      <div class="bg-white rounded-lg shadow-lg p-6 max-w-md w-full z-10">
        <div class="flex justify-between items-center mb-4">
          <h3 class="text-xl font-bold text-gray-800">Vahvista poistaminen</h3>
          <button @click="closeDeleteModal" class="text-gray-500 hover:text-gray-700 focus:outline-none">
            <font-awesome-icon icon="times" class="h-5 w-5" />
          </button>
        </div>
        <p v-if="selectedProject" class="text-gray-700 mb-6">
          Oletko varma, että haluat poistaa hankkeen "{{ selectedProject.name }}" pysyvästi? Tätä toimintoa ei voi
          peruuttaa.
        </p>
        <div class="flex justify-end">
          <button @click="closeDeleteModal"
            class="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2">
            Peruuta
          </button>
          <button @click="deleteProject(selectedProject.id)"
            class="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
            Poista hanke
          </button>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue';
import { database } from "@/firebase.js";
import { ref as firebaseRef, get, remove } from "firebase/database";
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import useAuthenticationCheck from '@/composables/useAuthenticationCheck';


export default {
  name: 'ProjectListView',
  setup() {
    const store = useStore();
    const projects = ref([]);
    const router = useRouter();
    const showDeleteModal = ref(false);
    const selectedProject = ref(null);

    const currentUser = computed(() => store.state.auth.user);
    const { checkAuthentication } = useAuthenticationCheck();

    onMounted(() => {
      checkAuthentication();
    });



    const fetchProjects = async () => {
      if (!currentUser.value) {
        console.log("Käyttäjä ei ole kirjautunut sisään.");
        return;
      }

      console.log("Fetching projects for user ID:", currentUser.value.uid);
      const projectsRef = firebaseRef(database, `users/${currentUser.value.uid}/projects`);
      const snapshot = await get(projectsRef);

      if (snapshot.exists()) {
        projects.value = Object.entries(snapshot.val()).map(([id, projectData]) => ({ id, ...projectData }));
      } else {
        console.log("Hankkeita ei löytynyt.");
        projects.value = [];
      }
    };

    onMounted(fetchProjects);

    watch(currentUser, (newUser) => {
      if (newUser) {
        fetchProjects();
      } else {
        projects.value = [];
      }
    });

    const logout = async () => {
      await store.dispatch('auth/logout');

    };

    const deleteProject = async (projectId) => {
      const currentUser = store.state.auth.user;
      if (!currentUser) {
        console.log("Käyttäjä ei ole kirjautunut sisään.");
        return;
      }
      await remove(firebaseRef(database, `users/${currentUser.uid}/projects/${projectId}`));
      await fetchProjects(); // Refresh the list after deletion
      if (projects.value.length === 0) {
        projects.value = []; // Clear the projects array if it's empty
      }
      closeDeleteModal();
    };

    const loadProject = (projectId) => {
      router.push({ name: 'HankeApuri', params: { projectId } });
    };

    onMounted(fetchProjects);

    watch(() => store.state.auth.user, (newUser) => {
      if (newUser) {
        fetchProjects();
      } else {
        projects.value = []; // Clear projects if user signs out
      }
    });

    const openDeleteModal = (project) => {
      selectedProject.value = project;
      showDeleteModal.value = true;
    };

    const closeDeleteModal = () => {
      selectedProject.value = null;
      showDeleteModal.value = false;
    };

    return {
      projects,
      loadProject,
      deleteProject,
      logout,
      showDeleteModal,
      selectedProject,
      openDeleteModal,
      closeDeleteModal,
    };
  },
};
</script>
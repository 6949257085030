import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyACof70nu0YChvxoh7Q6VtJ6HdbbjFuuHw",
  authDomain: "businessdemos.firebaseapp.com",
  databaseURL:
    "https://businessdemos-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "businessdemos",
  storageBucket: "businessdemos.appspot.com",
  messagingSenderId: "938938352568",
  appId: "1:938938352568:web:e30171ba497ea4bf49854d",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Initialize Firestore
const database = getDatabase(app);

export { database, auth };

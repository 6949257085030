// src/store/index.js
import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import auth from "./modules/auth";
import content from "./modules/content";
import files from "./modules/files";
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    auth: {
      user: state.auth.user,
      isAuthenticated: state.auth.isAuthenticated,
      // Add other necessary state properties to persist
    },
  }),
});

export default createStore({
  modules: {
    auth,
    content,
    files,
  },
  plugins: [vuexLocal.plugin],
});

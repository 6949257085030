<template>
    <div class="min-h-screen bg-gray-100 text-gray-900">
        <header class="px-4 lg:px-6 py-4 bg-white shadow">
            <div class="max-w-7xl mx-auto flex flex-wrap justify-between items-center">
                <div class="flex items-center flex-shrink-0 text-indigo-600 mr-6">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path
                            d="M9.5 2A2.5 2.5 0 0 1 12 4.5v15a2.5 2.5 0 0 1-4.96.44 2.5 2.5 0 0 1-2.96-3.08 3 3 0 0 1-.34-5.58 2.5 2.5 0 0 1 1.32-4.24 2.5 2.5 0 0 1 1.98-3A2.5 2.5 0 0 1 9.5 2Z">
                        </path>
                        <path
                            d="M14.5 2A2.5 2.5 0 0 0 12 4.5v15a2.5 2.5 0 0 0 4.96.44 2.5 2.5 0 0 0 2.96-3.08 3 3 0 0 0 .34-5.58 2.5 2.5 0 0 0-1.32-4.24 2.5 2.5 0 0 0-1.98-3A2.5 2.5 0 0 0 14.5 2Z">
                        </path>
                    </svg>
                    <span class="ml-2 text-2xl font-bold text-gray-800">Hankeapuri AI</span>
                </div>
                <div class="block lg:hidden">
                    <button @click="toggleMenu"
                        class="flex items-center px-3 py-2 border rounded text-indigo-600 border-indigo-600 hover:text-indigo-700 hover:border-indigo-700">
                        <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <title>Menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                        </svg>
                    </button>
                </div>
                <nav :class="{ 'hidden': !showMenu, 'block': showMenu }"
                    class="w-full flex-grow lg:flex lg:items-center lg:w-auto">
                    <div class="text-sm lg:flex-grow">
                        <a href="/osta"
                            class="block mt-4 lg:inline-block lg:mt-0 text-indigo-600 hover:text-indigo-700 mr-4">
                            Osta lisää
                        </a>
                        <p class="block mt-4 lg:inline-block lg:mt-0 text-gray-700 mr-4">
                            Krediittejä:
                            <span class="text-gray-900">{{ (userCredits / 100).toFixed(1) }}</span>
                        </p>
                        <a href="/hankkeet"
                            class="block mt-4 lg:inline-block lg:mt-0 text-indigo-600 hover:text-indigo-700 mr-4">
                            Omat hankkeet
                        </a>
                        <a href="/hankepohjat"
                            class="block mt-4 lg:inline-block lg:mt-0 text-indigo-600 hover:text-indigo-700 mr-4">
                            Hankepohjat
                        </a>
                        <span v-if="currentUser" class="block mt-4 lg:inline-block lg:mt-0 text-gray-700">
                            Käyttäjä: {{ currentUser.email }}
                        </span>
                    </div>
                    <div>
                        <a href="#" @click.prevent="handleLogout"
                            class="inline-block text-sm px-4 py-2 leading-none border rounded text-indigo-600 border-indigo-600 hover:border-transparent hover:text-white hover:bg-indigo-700 mt-4 lg:mt-0">
                            Kirjaudu ulos
                        </a>
                    </div>
                </nav>
            </div>
        </header>
        <main>
            <div class="py-12">
                <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div class="bg-white rounded-lg shadow-md p-6">
                        <h2 class="text-2xl font-semibold mb-8 text-center">Osta Krediittejä</h2>

                        <div class="flex justify-center">
                            <div class="max-w-lg bg-white rounded-lg shadow-lg p-8 text-center">
                                <div class="flex items-center justify-center mb-6">
                                    <svg class="w-8 h-8 text-green-500 mr-3" fill="none" stroke="currentColor"
                                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    <h2 class="text-3xl font-bold text-gray-800">{{ creditPackage.name }}</h2>
                                </div>
                                <p class="text-gray-600 text-lg mb-8">{{ creditPackage.description }}</p>
                                <p class="text-gray-700 mb-10">
                                    Tällä määrällä voit kirjoittaa 20 - yli 100 hakemusta riippuen hakemuksen
                                    laajuudesta ja syöttämistäsi tietomääristä. Krediitit ovat voimassa 1 vuoden
                                    ostopäivästä.
                                </p>
                                <button @click="purchaseCredits(creditPackage)"
                                    class="bg-green-500 text-white text-xl font-semibold px-10 py-4 rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50 shadow-xl transition duration-300 ease-in-out flex items-center justify-center mx-auto">
                                    <svg v-if="!loading" class="w-6 h-6 mr-3" fill="none" stroke="currentColor"
                                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z">
                                        </path>
                                    </svg>
                                    <svg v-else class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                        </path>
                                    </svg>
                                    Ei vielä toiminnassa, ota yhteyttä jos pilottitestaus kiinnostaa hello@jh-apps.com
                                    {{ creditPackage.price }}€ + ALV
                                </button>
                            </div>
                        </div>
                        <!-- Additional package option -->
                        <div class="flex justify-center mt-8">
                            <div class="max-w-lg bg-white rounded-lg shadow-lg p-8 text-center">
                                <div class="flex items-center justify-center mb-6">
                                    <svg class="w-8 h-8 text-green-500 mr-3" fill="none" stroke="currentColor"
                                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M7 13l4 4L19 7"></path>
                                    </svg>
                                    <h2 class="text-3xl font-bold text-gray-800">{{ tinyPackage.name }}</h2>
                                </div>
                                <p class="text-gray-600 text-lg mb-8">{{ tinyPackage.description }}</p>
                                <p class="text-gray-700 mb-10">
                                    Tämä pieni paketti täyttää muutaman hakemuksen. Krediitit ovat voimassa 1 vuoden
                                    ostopäivästä.
                                </p>
                                <button @click="purchaseCredits(tinyPackage)"
                                    class="bg-green-500 text-white text-xl font-semibold px-10 py-4 rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50 shadow-xl transition duration-300 ease-in-out flex items-center justify-center mx-auto">
                                    <svg v-if="!loading" class="w-6 h-6 mr-3" fill="none" stroke="currentColor"
                                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z">
                                        </path>
                                    </svg>
                                    <svg v-else class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                        </path>
                                    </svg>
                                    Ei vielä toiminnassa, ota yhteyttä jos pilottitestaus kiinnostaa hello@jh-apps.com
                                    {{ tinyPackage.price }}€ + ALV
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { database } from '@/firebase.js';
import { ref as firebaseRef, onValue } from 'firebase/database';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Swal from 'sweetalert2';

export default {
    setup() {
        const store = useStore();
        const loading = ref(false);

        const creditPackage = ref({
            name: 'Pro-paketti',
            description: '10 000 krediittiä',
            price: 129,
            credits: 1000000,
            productId: '293745', // Add the Lemon Squeezy product ID 
        });

        const tinyPackage = ref({
            name: 'Pikku Paketti',
            description: '1000 krediittiä',
            price: 19,
            credits: 100000,
            productId: '418794', // Add suitable Lemon Squeezy product ID 
        });

        const userCredits = ref(0);
        const tokensRemaining = ref(0);
        const currentUser = computed(() => store.state.auth.user);

        const fetchUserCredits = () => {
            const user = store.state.auth.user;
            if (user) {
                const userRef = firebaseRef(database, `users/${user.uid}`);
                onValue(userRef, (snapshot) => {
                    const userData = snapshot.val();
                    console.log('userData', userData);
                    if (userData && userData.credits) {
                        tokensRemaining.value = userData.credits;
                        userCredits.value = userData.credits;
                    }
                });
            }
        };

        const purchaseCredits = async (packageName) => {
            try {
                loading.value = true;
                const functions = getFunctions();
                const createLemonSqueezyCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
                const user = store.state.auth.user;
                const userId = user.uid;
                const result = await createLemonSqueezyCheckoutSession({
                    productId: packageName.productId,
                    customData: {
                        userId: userId,
                        credits: packageName.credits,
                    },
                    variantId: packageName.productId, // Send the variantId
                });
                const checkoutUrl = result.data.checkoutUrl;
                window.location.href = checkoutUrl;
            } catch (error) {
                console.error('Error purchasing credits:', error);
                Swal.fire({
                    icon: 'warning',
                    title: 'Huomio',
                    text: 'Tapahtui virhe krediittien ostossa. Ole hyvä ja yritä uudelleen.',
                });
            } finally {
                loading.value = false;
            }
        };

        const handleLogout = async () => {
            await store.dispatch('auth/logout');
            this.$router.push({ name: 'Login' });
        };

        onMounted(async () => {
            await store.dispatch('auth/init');
            fetchUserCredits();
        });

        return {
            creditPackage,
            tinyPackage,
            tokensRemaining,
            currentUser,
            purchaseCredits,
            handleLogout,
            userCredits,
            loading,
        };
    },
};
</script>
<template>
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
        <div class="bg-white rounded-lg p-8 m-4 max-w-xl w-full shadow-lg">
            <h2 class="text-2xl font-semibold mb-4">Anna Väliotsikko</h2>
            <input v-model="newSubKeyName" placeholder="Väliotsikko"
                class="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500" />
            <textarea v-model="newSubKeyDescription" placeholder="Täyttöohjeet"
                class="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500 mt-4"></textarea>
            <div class="flex justify-end space-x-4 mt-6">
                <button @click="cancel"
                    class="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 focus:outline-none">Peruuta</button>
                <button @click="confirm"
                    class="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 focus:outline-none">Lisää</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue';
import { database } from "@/firebase.js";
import { ref as firebaseRef, push } from "firebase/database";
import Swal from 'sweetalert2';

const newSubKeyName = ref('');
const newSubKeyDescription = ref('');
const emits = defineEmits(['confirm', 'cancel']);

const confirm = () => {
    if (newSubKeyName.value.trim()) {
        const newSubSectionId = push(firebaseRef(database, 'hankkeet')).key;
        // Adjusted to match the required structure
        emits('confirm', {
            id: newSubSectionId,
            text: {
                id: newSubSectionId,
                description: newSubKeyDescription.value.trim(),
                text: newSubKeyName.value.trim(),
            }
        });
        newSubKeyName.value = '';
        newSubKeyDescription.value = '';
    } else {
        Swal.fire({
            icon: 'warning',
            title: 'Huomio',
            text: 'Väliotsikko ei voi olla tyhjä.',
        });
    }
};

const cancel = () => {
    emits('cancel');
    newSubKeyName.value = '';
    newSubKeyDescription.value = '';
};
</script>
<template>
  <div class="flex flex-col min-h-screen">
    <TheHeader />
    <main class="flex-1">
      <section class="w-full py-12 md:py-24 lg:py-32 xl:py-48">
        <div class="container mx-auto px-4 md:px-6">
          <div class="grid gap-6 lg:grid-cols-[1fr_400px] lg:gap-12 xl:grid-cols-[1fr_600px]">
            <div class="flex flex-col items-center justify-center">
              <img src="../assets/kuva.png" alt="AI illustration"
                class="mx-auto aspect-video overflow-hidden rounded-xl object-cover sm:w-full lg:order-last lg:aspect-square"
                width="550" height="550" />
            </div>
            <div class="flex flex-col items-center justify-center space-y-4">
              <div class="space-y-2 text-center">
                <h1 class="text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none pb-2">
                  Hankeapuri AI - Älykäs apuri hankerahoitukseen - Tulossa Pian!
                </h1>
                <p class="max-w-[600px] text-gray-500 md:text-xl dark:text-gray-400 pb-2">
                  Tuoko hankkeen rahoitushakemus päänvaivaa? Helpota byrokratiaa ja säästä aikaa Hankeapuri AI:n avulla.
                  Syötä vain perustiedot ja toiveesi, ja anna tekoälyn hoitaa loput - luoden täsmälleen hankkeesi
                  vaatimukset täyttävän hakemuksen.
                </p>
                <p class="max-w-[600px] text-gray-500 md:text-xl dark:text-gray-400 pb-2">
                  Hyödynnä Hankeapuri AI:n älykkäät algoritmit tietojesi analysointiin ja rakenna vaikuttava
                  rahoitushakemus vakuuttaaksesi rahoittajat. Jos tarvitset muutoksia, Hankeapuri AI räätälöi
                  hakemuksen uudelleen yhdellä napsautuksella.
                </p>
                <p class="max-w-[600px] text-gray-500 md:text-xl dark:text-gray-400 pb-2">
                  Näin Hankeapuri AI säästää aikaasi ja vaivaa rahoitushakemusten parissa ja voit keskittyä itse
                  olennaiseen eli hankkeen suunnitteluun ja toteutukseen.
                </p>
              </div>
              <div class="flex flex-col gap-2 min-[400px]:flex-row justify-center">
                <a class="inline-flex h-10 items-center justify-center rounded-md bg-green-600 px-8 text-sm font-medium text-white shadow transition-colors hover:bg-green-700 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-green-600 disabled:pointer-events-none disabled:opacity-50"
                  :href="isLoggedIn ? '/hankkeet' : '/register'" rel="ugc">
                  {{ isLoggedIn ? 'Siirry Palveluun' : 'Aloita nyt' }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="py-12 md:py-24">
        <div class="container mx-auto px-4 md:px-6">
          <div class="flex flex-wrap items-center -mx-4 justify-center">
            <div class="w-full lg:w-1/2 px-4 mb-10 lg:mb-0 text-center">
              <h2 class="text-3xl font-bold mb-4">Säästä aikaa ja vaivaa hankerahoituksessa</h2>
              <p class="text-gray-500 mb-6">
                Hankeapuri AI automatisoi rahoitushakemusten luomisen, lyhentäen prosessin viikoista minuutteihin.
                Osallistu helpommin hankkeisiin ja keskity olennaiseen byrokratian sijaan.
              </p>
              <a href="/register"
                class="inline-block bg-blue-600 text-white rounded-md px-6 py-2 transition-colors hover:bg-blue-700">
                Tutustu tarkemmin
              </a>
            </div>
            <div class="w-full lg:w-1/2 px-4">
              <div class="bg-blue-100 p-6 rounded-2xl shadow-lg">
                <img src="kuva1.png" alt="Ajansäästö" class="mb-4 mx-auto" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="py-12 md:py-24">
        <div class="container mx-auto px-4 md:px-6">
          <div class="flex flex-wrap items-center -mx-4 justify-center">
            <div class="w-full lg:w-1/2 px-4">
              <div class="p-6 rounded-2xl shadow-lg">
                <img src="kuva2.png" alt="Tekoälyn voimaa" class="mb-4 mx-auto" />
              </div>
            </div>
            <div class="w-full lg:w-1/2 px-4 mb-10 lg:mb-0 text-center">
              <h2 class="text-3xl font-bold mb-4">Tekoälyn voimaa hankerahoitukseen</h2>
              <p class="text-gray-500 mb-6">
                Hankeapuri AI hyödyntää edistynyttä tekoälyä analysoidakseen hanketietoja ja luodakseen räätälöityjä
                rahoitushakemuksia.
                Älykäs algoritmi optimoi hakemuksesi vastaamaan rahoittajien vaatimuksia ja maksimoi
                onnistumismahdollisuutesi.
              </p>
              <a href="/register"
                class="inline-block bg-blue-600 text-white rounded-md px-6 py-2 transition-colors hover:bg-blue-700">
                Aloita käyttö
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="py-12 md:py-24">
        <div class="container mx-auto px-4 md:px-6">
          <div class="flex flex-col items-center justify-center space-y-4">
            <div class="space-y-2 text-center">
              <h2 class="text-3xl font-bold tracking-tighter sm:text-5xl pb-2">Katso demo video</h2>
              <p class="max-w-[600px] text-gray-500 md:text-xl dark:text-gray-400 pb-2">Näe miten Hankeapuri AI toimii
                käytännössä ja miten se voi auttaa sinua hankerahoituksessa.</p>
            </div>
            <div class="w-full relative" style="height: 350px; width:600px;">
              <div style="padding-top: 56.25%;"></div> <!-- 9 / 16 * 100% = 56.25% -->
              <iframe class="absolute top-0 left-0 w-full h-full rounded-xl shadow-lg"
                src="https://www.youtube.com/embed/N4sovTlsME4" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </section>

      <section class="w-full py-12 bg-gray-100 dark:bg-gray-800">
        <div class="container mx-auto px-4 md:px-6">
          <div class="w-full max-w-2xl mx-auto space-y-12">
            <div class="space-y-2 text-center">
              <h2 class="text-3xl font-bold tracking-tighter sm:text-5xl pb-3 text-white">
                Keskeiset Hyödyt
              </h2>
              <p
                class="max-w-[900px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
                Hankeapuri AI tarjoaa merkittäviä etuja rahoitushakemusten valmistelussa, vähentäen byrokraattista
                kuormitusta ja nopeuttaen varojen hankintaa.
              </p>
            </div>
            <div class="grid gap-6 md:grid-cols-2 lg:grid-cols-3 justify-center">
              <div
                class="rounded-lg border bg-white text-gray-800 shadow-md p-6 flex flex-col items-center transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
                <div class="flex flex-col space-y-2">
                  <h3 class="text-lg text-center font-semibold">Ajansäästö</h3>
                  <p class="text-gray-500 text-center">
                    Automatisoidut hakemuspohjat valmistuvat nopeasti ja vaivattomasti.
                  </p>
                </div>
              </div>
              <div
                class="rounded-lg border bg-white text-gray-800 shadow-md p-6 flex flex-col items-center transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
                <div class="flex flex-col space-y-2">
                  <h3 class="text-lg text-center font-semibold">Intuitiivinen</h3>
                  <p class="text-gray-500 text-center">
                    Voit aloittaa hankerahoituksen hakemisen heti keräämällä yrityksesi tiedot ja valitsemalla sopivan
                    hankkeen.
                  </p>
                </div>
              </div>
              <div
                class="rounded-lg border bg-white text-gray-800 shadow-md p-6 flex flex-col items-center transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
                <div class="flex flex-col space-y-2">
                  <h3 class="text-lg text-center font-semibold">Tekoälyn tuki</h3>
                  <p class="text-gray-500 text-center">
                    Vastaa vaatimuksiin ja kysymyksiin älykkäästi, tarjoten laadukasta sisältöä.
                  </p>
                </div>
              </div>
              <div
                class="rounded-lg border bg-white text-gray-800 shadow-md p-6 flex flex-col items-center transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
                <div class="flex flex-col space-y-2">
                  <h3 class="text-lg text-center font-semibold">Ajan hermolla</h3>
                  <p class="text-gray-500 text-center">
                    Hankkeen viimeisimmät ohjeistukset ja standardit ovat heti käytettävissäsi.
                  </p>
                </div>
              </div>
              <div
                class="rounded-lg border bg-white text-gray-800 shadow-md p-6 flex flex-col items-center transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
                <div class="flex flex-col space-y-2">
                  <h3 class="text-lg text-center font-semibold">Tehostaminen</h3>
                  <p class="text-gray-500 text-center">
                    Optimoi rahoitushakemuksen jokainen vaihe parantaen mahdollisuuksiasi menestyksekkääseen rahoituksen
                    saantiin.
                  </p>
                </div>
              </div>
              <div
                class="rounded-lg border bg-white text-gray-800 shadow-md p-6 flex flex-col items-center transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
                <div class="flex flex-col space-y-2">
                  <h3 class="text-lg text-center font-semibold">Omat Hanke Pohjat</h3>
                  <p class="text-gray-500 text-center">
                    Luo helposti omia hakemuspohjia, jotka auttavat missä tahansa hakemuksessa.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="w-full py-12 md:py-24 lg:py-32">
        <div class="container mx-auto px-4 md:px-6 flex flex-col items-center justify-center">
          <div class="space-y-2 text-center">
            <h2 class="text-3xl font-bold tracking-tighter md:text-4xl/tight">
              Oletko valmis kokeilemaan Hankeapuri AI:ta?
            </h2>
            <p
              class="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400 mb-2">
              Kokeile nyt ilmaiseksi Hankeapuri AI:ta ja koe itse miten helppoa hankkeiden laatiminen voi olla.
            </p>
          </div>
          <div class="flex flex-col gap-2 min-[400px]:flex-row justify-center mt-5">
            <a class="inline-flex h-10 items-center justify-center rounded-md bg-gray-900 px-8 text-m font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50"
              href="/register" rel="ugc">
              Kokeile nyt
            </a>
          </div>
        </div>
      </section>
    </main>
    <footer class="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
      <p class="text-xs text-gray-500 dark:text-gray-400">
        © Hankeapuri AI - JH Apps (Kaikki oikeudet pidätetään).
      </p>
      <nav class="sm:ml-auto flex gap-4 sm:gap-6">
        <a class="text-xs hover:underline underline-offset-4" href="/ehdot" rel="ugc">
          Käyttöehdot
        </a>
        <a class="text-xs hover:underline underline-offset-4" href="/yksityisyys" rel="ugc">
          Yksityisyys
        </a>
      </nav>
    </footer>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'HankeapuriAI',
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.state.auth.isAuthenticated);

    return {
      isLoggedIn,
    };
  },
};
</script>

<style scoped>
header {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

[data-v0-t='card'] {
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.bg-blue-100 {
  background-color: #ebf8ff;
}

.rounded-2xl {
  border-radius: 1.5rem;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
</style>
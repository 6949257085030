<template>
    <div v-if="aiResponse" class="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div v-for="(section, sectionKey) in aiResponse" :key="sectionKey" class="response-category mb-6">
            <div class="card bg-white p-4 rounded-lg shadow-lg">
                <div class="card-header bg-indigo-100 p-3 rounded-t-lg flex justify-between items-center mb-3">
                    <h2 class="text-xl font-bold text-indigo-600 card-header-title">{{ sectionKey }}</h2>
                    <div class="button-group">
                        <button @click="sendDefaultFeedback(sectionKey)" :disabled="loadingStates[sectionKey]"
                            class="auto-magic-icon bg-purple-600 hover:bg-purple-700 text-white font-bold p-3 rounded-full inline-flex items-center justify-center">
                            <span v-if="!loadingStates[sectionKey]" class="icon">
                                <font-awesome-icon :icon="['fas', 'robot']" size="lg" />
                            </span>
                            <span v-else class="spinner">
                                <font-awesome-icon :icon="['fas', 'spinner']" size="lg" class="fa-spin" />
                            </span>
                        </button>
                        <button @click="emitAiFixes(sectionKey)"
                            class="edit-icon bg-indigo-600 hover:bg-indigo-700 text-white font-bold p-3 rounded-full inline-flex items-center justify-center">
                            <font-awesome-icon :icon="['fas', 'magic']" size="lg" />
                        </button>
                        <button @click="copyToClipboard(section)"
                            class="copy-icon bg-green-600 hover:bg-green-700 text-white font-bold p-3 ml-2 rounded-full inline-flex items-center justify-center">
                            <font-awesome-icon :icon="['fas', 'copy']" size="lg" />
                        </button>
                        <button @click="clearContent(sectionKey, key)"
                            class="clear-icon bg-red-600 hover:bg-red-700 text-white font-bold p-3 ml-2 rounded-full inline-flex items-center justify-center">
                            <font-awesome-icon :icon="['fas', 'trash']" size="lg" />
                        </button>
                    </div>
                </div>
                <div class="card-content text-gray-700">


                    <div v-for="(text, key) in section" :key="key" class="nested-field mt-2">
                        <strong class="main-key mb-5">{{ key }}</strong>
                        <div>
                            <span v-if="!isEditing(sectionKey, key)" @click="openEditModal(sectionKey, key)"
                                class="editable pt-2">{{ text || 'Tyhjä' }}</span>
                            <textarea v-else :id="`textarea-${sectionKey}-${key}`"
                                v-model="editableValues[sectionKey + key]" @blur="saveEdits(sectionKey, key)"
                                @keyup.enter="saveEdits(sectionKey, key)" @input="autoResize" @click="autoResize"
                                class="textarea-autoresize mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"></textarea>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <EditModal v-model="modalState" :givenKey="modalState.sectionKey" :givenSubKey="modalState.key"
        @submit-edit="handleSubmitEdit" @aiResponseUpdated="handleAiResponseUpdate" :updatedContent="updatedContent">
    </EditModal>

</template>


<script>
import { ref, reactive, defineComponent, toRefs, nextTick, computed } from 'vue';
import EditModal from './editModal.vue';
import { useStore } from 'vuex';
import { mapGetters } from 'vuex';

export default defineComponent({
    components: {
        EditModal
    },
    computed: {
        ...mapGetters('content', ['getLoadingStates']),
        loadingStates() {
            return this.getLoadingStates;
        },
    },
    emits: ['submit-edit', 'ai-fixes', 'update-ai-response', 'submit-feedback-subkey'],
    setup(props, { emit }) {
        const store = useStore();
        const modalState = ref({ isVisible: false, content: '', sectionKey: '', key: '' });
        const isSendingFeedback = ref(false);
        const loadingStates = ref({});
        // Use the aiResponse from the store
        const aiResponse = computed(() => store.getters['content/getAiResponse']);

        const handleSubmitEdit = (givenKey, givenSubKey, feedbackText) => {
            emit('submit-feedback-subkey', givenKey, givenSubKey, feedbackText);
        };

        const openEditModal = (sectionKey, key) => {
            modalState.value = {
                isVisible: true,
                content: aiResponse.value[sectionKey][key],
                sectionKey: sectionKey,
                key: key,
            };
        };


        const clearContent = (sectionKey) => {
            const updatedAiResponse = { ...aiResponse.value };
            // Clear all key-value pairs within the specified section
            Object.keys(updatedAiResponse[sectionKey]).forEach(key => {
                updatedAiResponse[sectionKey][key] = '';
            });
            store.dispatch('content/updateAiResponse', updatedAiResponse);
        };



        const handleAiResponseUpdate = (updatedAiResponse) => {
            console.log('handleAiResponseUpdate', updatedAiResponse);
            store.dispatch('content/updateAiResponse', updatedAiResponse);
        };



        const resetLoadingState = (sectionKey) => {
            loadingStates.value[sectionKey] = false;
        };



        const sendDefaultFeedback = (sectionKey) => {
            const defaultFeedbackText = "Kirjoita tekstit annettujen ohjeiden ja hanketietojen mukaisesti";
            console.log("sending default feedback");
            console.log("sectionKey" + sectionKey);
            // Dispatch the action to set the loading state to true for the specific section
            store.dispatch('content/updateLoadingState', { key: sectionKey, value: true });
            emit('submit-feedback', { key: sectionKey, feedbackTextDefault: defaultFeedbackText });
            console.log(loadingStates);
        };

        const autoResize = (event) => {
            const element = event.target;
            element.style.height = 'inherit';
            const computed = window.getComputedStyle(element);
            const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
                + parseInt(computed.getPropertyValue('padding-top'), 10)
                + element.scrollHeight
                + parseInt(computed.getPropertyValue('padding-bottom'), 10)
                + parseInt(computed.getPropertyValue('border-bottom-width'), 10);
            element.style.height = `${height}px`;
        };

        const copyToClipboard = (section) => {
            const el = document.createElement('textarea');
            el.value = Object.values(section).join('\n');
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);

        };

        const isObject = (value) => {
            return value && typeof value === 'object' && !Array.isArray(value);
        };

        const isArray = (value) => {
            return Array.isArray(value);
        };

        const emitAiFixes = (key) => {
            console.log('key', key);
            emit('ai-fixes', key);
        };

        const editingKeys = ref({});
        const editableValues = reactive({});

        const enableEditing = (sectionKey, key) => {
            editingKeys.value[sectionKey + key] = true;
            editableValues[sectionKey + key] = aiResponse.value[sectionKey][key];
            nextTick(() => {
                const textArea = document.getElementById(`textarea-${sectionKey}-${key}`);
                if (textArea) {
                    autoResize({ target: textArea });
                }
            });
        };

        const isEditing = (sectionKey, key) => {
            return editingKeys.value[sectionKey + key];
        };

        const saveEdits = (sectionKey, key) => {
            if (editableValues[sectionKey + key] !== aiResponse.value[sectionKey][key]) {
                store.dispatch('updateAiResponse', {
                    sectionKey,
                    key,
                    value: editableValues[sectionKey + key]
                });
            }
            editingKeys.value[sectionKey + key] = false;
        };

        return {
            ...toRefs(aiResponse.value),
            isObject,
            isArray,
            emitAiFixes,
            enableEditing,
            saveEdits,
            isEditing,
            editableValues,
            autoResize,
            copyToClipboard,
            sendDefaultFeedback,
            isSendingFeedback,
            resetLoadingState,
            modalState,
            openEditModal,
            handleSubmitEdit,
            handleAiResponseUpdate,
            clearContent,

            aiResponse,
        };
    }
});
</script>
<style scoped>
strong {
    font-weight: bold;
    margin-right: 5px;
}

.textarea-autoresize {
    min-height: 38px;
    /* The initial height */
    overflow-y: hidden;
    /* Hide the scrollbar */
    resize: none;
    /* Disable manual resizing */
}

.card-content {
    font-size: 1.125em;
    /* Increased from 1em to 1.125em for larger text */
}

.main-key {
    font-size: 1.15rem;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

}

.card-header {
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

strong.font-bold {
    font-size: 1.05rem;
    /* Slightly larger */
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
}

span.editable {
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.textarea-autoresize {
    font-size: 1.05rem;
    /* Slightly larger text for readability */
    transition: box-shadow 0.3s ease;
}

.textarea-autoresize:focus {
    box-shadow: 0 0 0 2px #cbd5e1;
    /* More pronounced focus state */
}

span.editable:hover {
    background-color: #f3f4f6;
    /* Light grey background on hover */
}

.card-header-title {
    /* This is for the header text */
    flex-grow: 1;
    /* This will push the buttons to the right */
}

.nested-field {
    margin-bottom: 1.5rem;
    /* Increased from 0.5rem */
}

.button-group {
    /* A new class for the button group */
    display: flex;
    align-items: center;
}

.edit-icon,
.copy-icon {
    /* Shared button styles for a consistent look */
    background-color: #4c51bf;
    /* A regal indigo base */
    color: #fff;
    /* As pure as freshly fallen snow */
    padding: 0.75rem;
    /* Just enough padding to be comfy */
    border-radius: 9999px;
    /* As round as a full moon */
    font-weight: bold;
    /* Strong like a... well, a bold font */
    line-height: 1;
    /* Keep it tight */
    transition: background-color 0.3s;
    /* Smooth like butter */
    cursor: pointer;
    /* The hand of invitation */
    margin-left: 0.5rem;
    /* A polite space between friends */
}

.edit-icon:hover,
.copy-icon:hover {
    background-color: #5a67d8;
    /* A touch lighter when hovered, like a cloud passing the moon */
}

.card {
    margin: 1rem 0;
    padding: 1rem;
    transition: transform 0.3s ease;

    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Shadow that whispers secrets */
    border-radius: 8px;
    /* The Goldilocks of border radii */

}

@keyframes bobbing {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-5px);
    }
}
</style>

<template>
    <TheHeader />
    <main class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-md w-full space-y-8">
            <div>



                <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Rekisteröidy Hankeapuri AI:hin</h2>
            </div>
            <div class="rounded-lg border bg-white text-card-foreground shadow-m" data-v0-t="card">
                <div class="flex flex-col space-y-1.5 p-6">
                    <h3 class="font-semibold tracking-tight text-2xl">Rekisteröidy</h3>
                    <p class="text-sm text-muted-foreground">Luo uusi tili syöttämällä sähköpostiosoitteesi ja
                        salasanasi.</p>
                </div>
                <div class="p-6 space-y-4">
                    <label
                        class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        for="email">
                        Sähköpostiosoite
                    </label>
                    <input
                        class="h-10 bg-background text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        id="email" v-model="email" required="" type="email" />
                    <div class="space-y-2">
                        <label
                            class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            for="password">
                            Salasana
                        </label>
                        <input
                            class="h-10 bg-background text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            id="password" v-model="password" required="" type="password" />
                    </div>
                    <div class="space-y-2">
                        <label
                            class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            for="confirmPassword">
                            Vahvista salasana
                        </label>
                        <input
                            class="h-10 bg-background text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            id="confirmPassword" v-model="confirmPassword" required="" type="password" />
                    </div>
                </div>
            </div>
            <div>
                <button @click="registerWithEmail" :disabled="isRegistering"
                    class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    {{ isRegistering ? 'Odota...' : 'Rekisteröidy sähköpostilla' }}
                </button>
                <button @click="registerWithGoogle"
                    class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-600 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Rekisteröidy Googlella
                </button>
            </div>
            <div v-if="successMessage" class="text-green-500">{{ successMessage }}</div>
            <div v-if="error" class="text-red-500">{{ error }}</div>
        </div>
    </main>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const router = useRouter();
const email = ref('');
const password = ref('');
const confirmPassword = ref('');
const error = ref('');
const successMessage = ref('');
const isRegistering = ref(false);

const registerWithGoogle = async () => {
    try {
        await store.dispatch('auth/loginWithGoogle');
        successMessage.value = "Rekisteröityminen onnistui! Uudelleenohjataan...";
        setTimeout(() => {
            router.push({ name: 'projectList' });
        }, 2000);
    } catch (e) {
        error.value = `Virhe: ${e.message}`;
    }
};

const registerWithEmail = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email.value);

    if (!isValidEmail) {
        error.value = "Anna validi sähköpostiosoite.";
        return;
    }

    if (!email.value || !password.value || !confirmPassword.value) {
        error.value = "Sinun on syötettävä sähköpostiosoite, salasana ja vahvistettava salasana.";
        return;
    }

    if (password.value !== confirmPassword.value) {
        error.value = "Salasanat eivät täsmää. Varmista, että olet syöttänyt saman salasanan molempiin kenttiin.";
        return;
    }

    isRegistering.value = true;

    try {
        await store.dispatch('auth/registerWithEmail', { email: email.value, password: password.value });
        successMessage.value = "Rekisteröityminen onnistui! Uudelleenohjataan...";
        setTimeout(() => {
            router.push({ name: 'projectList' });
        }, 2000);
    } catch (e) {
        error.value = `Virhe: ${e.message}`;
    } finally {
        isRegistering.value = false;
    }
};
</script>
<template>
    <div class="container mx-auto px-4 py-8">
        <h1 class="text-3xl font-bold mb-4">Käyttöehdot</h1>
        <p class="text-gray-600 mb-8">Päivitetty viimeksi: 4.4.2024</p>


        <div class="mb-8">
            <h2 class="text-2xl font-bold mb-2">1. Palvelun kuvaus</h2>
            <p class="text-gray-600">
                Hankeapuri AI on verkkopalvelu, joka auttaa käyttäjiä laatimaan hankerahoitushakemuksia hyödyntäen
                tekoälyä. Palvelu tarjoaa työkaluja ja resursseja hakemusten luomiseen ja hallintaan.
            </p>
        </div>

        <div class="mb-8">
            <h2 class="text-2xl font-bold mb-2">2. Käyttöoikeus</h2>
            <p class="text-gray-600">
                Palvelun käyttö on sallittu vain 18 vuotta täyttäneille henkilöille tai alaikäisille, joilla on
                huoltajan suostumus. Rekisteröitymällä Palveluun vakuutat, että täytät nämä vaatimukset.
            </p>
        </div>

        <div class="mb-8">
            <h2 class="text-2xl font-bold mb-2">3. Käyttäjätilit</h2>
            <p class="text-gray-600">
                Palvelun käyttö edellyttää käyttäjätilin luomista. Olet vastuussa tilin tietojen oikeellisuudesta ja
                salasanan luottamuksellisuudesta. Ilmoita meille välittömästi, jos epäilet tiliisi murtaudutun.
            </p>
        </div>

        <div class="mb-8">
            <h2 class="text-2xl font-bold mb-2">4. Maksut ja tilaukset</h2>
            <p class="text-gray-600">
                Osa Palvelun ominaisuuksista voi olla maksullisia. Maksut käsitellään Lemon Squeezy -maksupalvelun
                kautta. Emme tallenna luottokorttitietojasi. Tilaukset uusiutuvat automaattisesti, kunnes ne perutaan.
            </p>
        </div>

        <div class="mb-8">
            <h2 class="text-2xl font-bold mb-2">5. Immateriaalioikeudet</h2>
            <p class="text-gray-600">
                Palvelun sisältö, kuten tekstit, kuvat ja logot, ovat meidän omaisuuttamme ja tekijänoikeuksien
                suojaamia. Käyttäjien luoma sisältö pysyy heidän omaisuutenaan, mutta myöntämällä meille oikeuden
                käyttää sitä Palvelun tarjoamiseen.
            </p>
        </div>

        <div class="mb-8">
            <h2 class="text-2xl font-bold mb-2">6. Vastuun rajoitukset</h2>
            <p class="text-gray-600">
                Palvelu tarjotaan "sellaisena kuin se on" ilman takuita. Emme ole vastuussa mistään vahingoista, jotka
                aiheutuvat Palvelun käytöstä tai kyvyttömyydestä käyttää sitä.
            </p>
        </div>

        <div class="mb-8">
            <h2 class="text-2xl font-bold mb-2">7. Käyttäjien velvollisuudet</h2>
            <p class="text-gray-600">
                Sitoudut käyttämään Palvelua laillisesti ja näiden Ehtojen mukaisesti. Et saa jakaa valheellista tietoa,
                häiritä muita käyttäjiä tai yrittää vahingoittaa Palvelua millään tavalla.
            </p>
        </div>

        <div class="mb-8">
            <h2 class="text-2xl font-bold mb-2">8. Tietosuoja</h2>
            <p class="text-gray-600">
                Keräämme ja käsittelemme henkilötietojasi Yksityisyyskäytännön mukaisesti. Käyttämällä Palvelua hyväksyt
                tietojesi käsittelyn kuvatulla tavalla.
            </p>
        </div>

        <div class="mb-8">
            <h2 class="text-2xl font-bold mb-2">9. Muutokset käyttöehtoihin</h2>
            <p class="text-gray-600">
                Voimme ajoittain muuttaa näitä Ehtoja. Merkittävistä muutoksista ilmoitetaan Palvelussa ja/tai
                sähköpostitse. Jatkamalla Palvelun käyttöä muutosten jälkeen hyväksyt päivitetyt Ehdot.
            </p>
        </div>

        <div class="mb-8">
            <h2 class="text-2xl font-bold mb-2">10. Sovellettava laki ja riitojen ratkaisu</h2>
            <p class="text-gray-600">
                Näihin Ehtoihin sovelletaan Suomen lakia. Mahdolliset riidat pyritään ensisijaisesti ratkaisemaan
                neuvotteluteitse. Jos ratkaisuun ei päästä, riidat ratkaistaan Helsingin käräjäoikeudessa.
            </p>
        </div>

        <p class="text-gray-600">
            Jos Sinulla on kysyttävää näistä Ehdoista, ota yhteyttä: <a href="mailto:hello@jh-apps.com"
                class="text-blue-600 hover:underline">hello@jh-apps.com</a>.
        </p>
    </div>
</template>


<script>
export default {
    name: 'TermsView',
}
</script>

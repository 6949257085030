// src/main.js

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store/index";
import TheHeader from "./components/TheHeader.vue";
import router from "./router";
import "./index.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.css";
import {
  faMicrophone,
  faMicrophoneSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas); // Add all solid icons to the library (or just the ones you need)
library.add(faMicrophone, faMicrophoneSlash);

const app = createApp(App);
app.use(router);
app.use(store);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("TheHeader", TheHeader);

app.mount("#app");

<template>
    <div v-if="isVisible" class="fixed inset-0 bg-gray-900 bg-opacity-90 z-50 flex justify-center items-center">
        <div class="bg-white p-8 rounded-2xl shadow-2xl max-w-4xl w-full relative transform scale-90">
            <button @click="close"
                class="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition duration-200 focus:outline-none">
                <font-awesome-icon :icon="['fas', 'times']" size="lg" />
            </button>
            <h2 class="text-3xl font-bold text-gray-800 mb-6">Muokkaa sisältöä</h2>
            <textarea v-model="editableContent" @input="autoResize"
                class="textarea-autoresize w-full p-4 border-2 border-gray-300 rounded-lg text-gray-700 text-lg leading-relaxed focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 resize-none overflow-y-auto"></textarea>
            <div class="flex justify-end space-x-4 mt-8">
                <button @click="toggleRecording"
                    class="record-button flex items-center justify-center bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <font-awesome-icon v-show="!isRecording && !isLoading" :icon="['fas', 'microphone']"
                        class="text-white mr-2" />
                    <font-awesome-icon v-show="isRecording && !isLoading" :icon="['fas', 'microphone-slash']"
                        class="text-white mr-2" />
                    <span v-if="isLoading" class="mr-2">
                        <div class="spinner">
                            <div class="double-bounce1"></div>
                            <div class="double-bounce2"></div>
                        </div>
                    </span>
                    <span>{{ isRecording ? 'Lopeta' : 'Äänitä' }}</span>
                </button>
                <button @click="submitEdit" :disabled="getIsProcessing.submitEdit"
                    class="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-6 rounded-lg shadow-md transition duration-200 flex items-center justify-center h-12 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                    <span v-if="getIsProcessing.submitEdit" class="mr-2">
                        <div class="spinner">
                            <div class="double-bounce1"></div>
                            <div class="double-bounce2"></div>
                        </div>
                    </span>
                    <span v-else>
                        <font-awesome-icon :icon="['fas', 'robot']" class="text-white mr-2" />
                        Luo AI
                    </span>
                </button>
                <button @click="processText" :disabled="isProcessing.processText"
                    class="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-6 rounded-lg shadow-md transition duration-200 flex items-center justify-center h-12 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                    <span v-if="isProcessing.processText" class="mr-2">
                        <div class="spinner">
                            <div class="double-bounce1"></div>
                            <div class="double-bounce2"></div>
                        </div>
                    </span>
                    <span>Oikolue AI</span>
                </button>
                <button @click="processTextWithClaude" :disabled="isProcessing.processTextWithClaude"
                    class="bg-purple-600 hover:bg-purple-700 text-white font-semibold py-2 px-6 rounded-lg shadow-md transition duration-200 flex items-center justify-center h-12 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                    <span v-if="isProcessing.processTextWithClaude" class="mr-2">
                        <div class="spinner">
                            <div class="double-bounce1"></div>
                            <div class="double-bounce2"></div>
                        </div>
                    </span>
                    <span>Oikolue Claude AI</span>
                </button>
                <button @click="save"
                    class="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-6 rounded-lg shadow-md transition duration-200 h-12 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">Tallenna</button>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, watchEffect, computed } from 'vue';
import { useStore, mapGetters } from 'vuex';
import axios from 'axios';
import Swal from 'sweetalert2';
import { transcribeAudio } from "@/services/apiService";

export default defineComponent({
    props: {
        updatedContent: {
            type: String,
            default: '',
        },
        modelValue: {
            type: Object,
            required: true,
        },
        givenKey: {
            type: String,
            required: true,
        },
        givenSubKey: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters('content', ['getIsProcessing']),
    },
    emits: ['update:modelValue', 'submit-feedback'],
    setup(props, { emit }) {
        const store = useStore();
        const isProcessing = ref({
            submitEdit: false,
            processText: false,
            processTextWithClaude: false,
        });
        const isLoading = ref(false);
        const isRecording = ref(false);
        let mediaRecorder;
        let audioChunks = [];

        const isVisible = computed(() => props.modelValue.isVisible);
        const editableContent = computed({
            get: () => store.getters['content/getEditableContent'],
            set: (value) => store.dispatch('content/updateEditableContent', value),
        });

        watchEffect(() => {
            editableContent.value = props.modelValue.content;
        });

        const sendAudioToCloud = async (audioBlob) => {
            try {
                isLoading.value = true; // Set loading status to true before processing

                const reader = new FileReader();
                reader.readAsDataURL(audioBlob);
                reader.onloadend = async () => {
                    const base64Audio = reader.result.split(',')[1];
                    const response = await transcribeAudio(base64Audio);
                    console.log(response.data);
                    if (response && response.data.text) {
                        editableContent.value += response.data.text;
                    }
                    isLoading.value = false;
                };
            } catch (error) {
                console.error("Alas, the spell failed:", error);
                isLoading.value = false;
            }
        };

        const processTextWithClaude = async () => {
            try {
                const formData = new FormData();
                isProcessing.value.processTextWithClaude = true;
                formData.append('text', editableContent.value);
                console.log(editableContent.value);
                const response = await axios.post('https://www.jhappssandbox.com/gptserver/chat/hankeapurioikolukuClaude.php', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (response && response.data) {
                    editableContent.value = response.data;
                }
            } catch (error) {
                console.error("Oops, something went wrong:", error);
                Swal.fire({
                    icon: 'warning',
                    title: 'Huomio',
                    text: 'Tapahtui virhe tekstin käsittelyssä. Ole hyvä ja yritä uudelleen.',
                });
            }
        };

        const processText = async () => {
            try {
                const formData = new FormData();
                isProcessing.value.processText = true;
                formData.append('text', editableContent.value);
                const response = await axios.post('https://www.jhappssandbox.com/gptserver/chat/hankeapurioikoluku.php', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (response && response.data) {
                    editableContent.value = response.data;
                }
            } catch (error) {
                console.error("Oops, something went wrong:", error);
                Swal.fire({
                    icon: 'warning',
                    title: 'Huomio',
                    text: 'Tapahtui virhe tekstin käsittelyssä. Ole hyvä ja yritä uudelleen.',
                });
            } finally {
                isProcessing.value.processText = false;
            }
        };

        const toggleRecording = async () => {
            if (!isRecording.value) {
                if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
                    alert("Audio recording is not supported in this browser.");
                    return;
                }
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                mediaRecorder = new MediaRecorder(stream);
                audioChunks = [];
                mediaRecorder.ondataavailable = event => {
                    audioChunks.push(event.data);
                };
                mediaRecorder.onstop = async () => {
                    const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
                    console.log("Recording stopped, process the blob:", audioBlob);

                    await sendAudioToCloud(audioBlob);

                };
                mediaRecorder.start();
                isRecording.value = true;
            } else {
                mediaRecorder.stop();
                isRecording.value = false;
            }
        };

        const save = () => {
            const aiResponse = store.getters['content/getAiResponse'];
            console.log(aiResponse);
            console.log(props.givenKey);
            console.log(props.givenSubKey);

            if (aiResponse && aiResponse[props.givenKey] && aiResponse[props.givenKey][props.givenSubKey]) {
                aiResponse[props.givenKey][props.givenSubKey] = editableContent.value;
                store.dispatch('content/updateAiResponse', aiResponse);
                emit('update:modelValue', { isVisible: false, content: editableContent.value });
            } else {
                console.error('Invalid aiResponse structure or missing keys:', props.givenKey, props.givenSubKey);
            }


        };

        const close = () => {
            emit('update:modelValue', { ...props.modelValue, isVisible: false });
        };

        const submitEdit = async () => {
            try {
                console.log('Emitting submit-feedback');
                const aiResponse = store.getters['content/getAiResponse'];
                aiResponse[props.givenKey][props.givenSubKey] = editableContent.value;
                store.dispatch('content/updateAiResponse', aiResponse);
                store.dispatch('content/updateIsProcessing', { key: 'submitEdit', value: true });
                await emit('submit-edit', props.givenKey, props.givenSubKey, editableContent.value);
            } catch (error) {
                console.error('Error submitting edit:', error);
            }
        };

        const autoResize = (event) => {
            event.target.style.height = 'auto';
            event.target.style.height = `${event.target.scrollHeight}px`;
        };

        return {
            editableContent,
            save,
            close,
            isVisible,
            autoResize,
            isRecording,
            toggleRecording,
            processText,
            isProcessing,
            processTextWithClaude,
            submitEdit,
            isLoading
        };
    },
});
</script>

<style scoped>
.textarea-autoresize {
    min-height: 500px;
    overflow-y: auto;
}

.record-button {
    @apply bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-200;
}

.process-button {
    @apply text-white font-bold py-2 px-4 rounded-lg shadow transition duration-200 flex items-center justify-center;
}

.process-button-oikolue {
    @apply bg-purple-600 hover:bg-purple-800;
}

.process-button-claude {
    @apply bg-green-600 hover:bg-green-800;
}

.save-button {
    @apply bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-lg shadow transition duration-200;
}

.spinner {
    width: 24px;
    height: 24px;
    position: relative;
    display: inline-block;
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
    animation-delay: -1.0s;
}

@keyframes sk-bounce {

    0%,
    100% {
        transform: scale(0.0);
    }

    50% {
        transform: scale(1.0);
    }
}

.spinner {
    width: 24px;
    height: 24px;
    position: relative;
    display: inline-block;
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
    animation-delay: -1.0s;
}

@keyframes sk-bounce {

    0%,
    100% {
        transform: scale(0.0);
    }

    50% {
        transform: scale(1.0);
    }
}
</style>

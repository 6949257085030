<template>
    <TheHeader />
    <main class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-md w-full space-y-8">
            <div>
                <img class="mx-auto h-12 w-auto" src="https://via.placeholder.com/48x48.png?text=🧠" alt="Brainy icon" />
                <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Create your account</h2>
            </div>
            <form class="mt-8 space-y-6" @submit.prevent="register">
                <div class="rounded-md shadow-sm -space-y-px">
                    <div>
                        <label for="email" class="sr-only">Email address</label>
                        <input id="email" name="email" type="email" autocomplete="email" required
                            class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            placeholder="Email address" v-model="email">
                    </div>
                    <div>
                        <label for="password" class="sr-only">Password</label>
                        <input id="password" name="password" type="password" autocomplete="new-password" required
                            class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            placeholder="Password" v-model="password">
                    </div>
                    <div>
                        <label for="password-confirm" class="sr-only">Confirm Password</label>
                        <input id="password-confirm" name="password-confirm" type="password" autocomplete="new-password"
                            required
                            class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            placeholder="Confirm Password" v-model="confirmPassword">
                    </div>
                </div>

                <div class="flex items-center justify-between">
                    <div class="flex items-center">
                        <input id="terms-and-conditions" name="terms-and-conditions" type="checkbox"
                            class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
                        <label for="terms-and-conditions" class="ml-2 block text-sm text-gray-900">
                            I agree to the <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">terms and
                                conditions</a>.
                        </label>
                    </div>
                </div>

                <div>
                    <button type="submit"
                        class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Sign up
                    </button>
                    <div v-if="error" class="error-message">{{ error }}</div>
                </div>
            </form>
            <p class="mt-2 text-center text-sm text-gray-600">
                Already have an account?
                <router-link to="/login" class="font-medium text-indigo-600 hover:text-indigo-500">
                    Sign in
                </router-link>
            </p>
        </div>
    </main>
</template>

<script setup>
import { ref } from 'vue';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { useRouter } from 'vue-router';

const auth = getAuth();
const router = useRouter();
const email = ref('');
const password = ref('');
const confirmPassword = ref('');
const error = ref('');

const register = async () => {
    if (password.value !== confirmPassword.value) {
        error.value = "The two passwords are not dancing to the same tune. They must match!";
        return;
    }
    try {
        await createUserWithEmailAndPassword(auth, email.value, password.value);
        router.push({ name: 'hankeapuri' });
    } catch (e) {
        if (e.code === 'auth/email-already-in-use') {
            error.value = "This email is already doing the tango in our system. Try logging in instead!";
        } else {
            error.value = `Oh, the drama! ${e.message}`;
        }
    }
};
</script>

<style scoped>
/* Add any additional styles you want to customize your signup page */
.error-message {
    color: #ff4136;
    /* A fiery red for errors */
    margin: 1rem 0;
    /* Give it some space */
}
</style>